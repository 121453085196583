import styled from '@emotion/styled';
import {
  color_darkest_grey,
  color_light_grey,
  color_dark_grey,
} from '@src/styles/colors';
import { getStyleSizes } from '@styles/styles.utils';
import { mq_min, mq_max } from '@styles/breakpoints';
import { WithVariantProps, ImageContainerProps } from './logo-card.interface';
import { css } from '@emotion/react';

export const LogoCardContainer = styled('div')<
  WithVariantProps & { hasVideo?: boolean }
>`
  border-bottom: 1px solid ${color_light_grey};
  ${mq_min('lg')} {
    border: 1px solid ${color_light_grey};
    border-radius: ${getStyleSizes(4)};
  }
  ${({ variant }) => {
    return (
      variant === 'mobile-stacked' &&
      css`
        border: 1px solid ${color_light_grey};
        border-radius: ${getStyleSizes(4)};
      `
    );
  }}
`;

export const VideoContainer = styled('div')`
  width: 100%;
  background: black;
`;

export const Container = styled('div')<
  WithVariantProps & { hasVideo?: boolean }
>`
  display: flex;
  color: ${color_darkest_grey};
  flex-direction: row;
  padding: ${getStyleSizes([0, 0, 40])};
  align-items: flex-start;
  ${({ variant, hasVideo }) => {
    return (
      variant === 'mobile-stacked' &&
      css`
        border-radius: ${!hasVideo
          ? getStyleSizes(4)
          : getStyleSizes([0, 0, 4, 4])} !important;
        flex-direction: column;

        align-items: center;
        padding: ${getStyleSizes([0, 45, 0, 45])};
      `
    );
  }}

  ${mq_min('lg')} {
    flex-direction: column;
    padding: ${getStyleSizes([0, 45, 0, 45])};
    ${({ hasVideo }) => {
      return !hasVideo
        ? css`
            height: 100%;
          `
        : css`
            padding: ${getStyleSizes([32, 45, 0, 45])};
          `;
    }}
  }
`;

export const Inner = styled('div')<WithVariantProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  color: ${color_dark_grey};
  margin-top: ${getStyleSizes(24)};
  ${({ variant }) => {
    return (
      variant === 'mobile-stacked' &&
      css`
        padding-bottom: ${getStyleSizes(40)};
        margin-top: ${getStyleSizes(20)};
      `
    );
  }}
  ${mq_min('lg')} {
    padding-bottom: ${getStyleSizes(40)};
    margin-top: ${getStyleSizes(0)};
  }
`;

export const ContainerFooter = styled('footer')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
`;

export const Title = styled('h3')`
  font-weight: 600;
  font-size: ${getStyleSizes(18)};
  color: ${color_darkest_grey};
`;

export const ImageContainer = styled('div')<ImageContainerProps>`
  display: flex;
  width: 45%;
  justify-content: center;
  padding-right: ${getStyleSizes(30)};
  margin-top: ${getStyleSizes(24)};
  margin-bottom: ${getStyleSizes(8)};

  ${({ variant }) =>
    variant === 'mobile-stacked' &&
    css`
      padding: 0;
      width: 100%;
    `}

  ${mq_min('lg')} {
    padding: 0;
    width: 100%;
    max-height: ${getStyleSizes(224)};
    overflow: hidden;
    height: 13.5rem;
  }

  .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
    }
  }
`;
